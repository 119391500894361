import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { Box } from 'rebass';
import slugify from '@sindresorhus/slugify';

import Layout from '../components/Layout';
import Img from 'gatsby-image';
import { cartesian } from '../utils/randomize';
import { CTA } from '../components/ui/CTA';
import { ArticleContainer } from '../components/ui/ArticleContainer';
import Button from '../components/ui/Button';
import ALL_PARTNERS from '../partners';

const ImageWrapper = styled.div`
  max-width: 650px;
`;

function DataCell({
  bold = false,
  children,
}: {
  bold?: boolean;
  children: React.ReactNode;
}) {
  return <td>{bold ? <b>{children}</b> : children}</td>;
}

function getRandomizedTerm({
  terms,
  index,
}: {
  terms: string[];
  index: number;
}) {
  return terms[index % terms.length];
}

function getRandomizedTitle({
  index,
  cityName,
}: {
  index: number;
  cityName: string;
}) {
  return `${getRandomizedTerm({
    terms: ['Entreprise', 'Société', 'Agence'],
    index,
  })} de sécurité ${getRandomizedTerm({
    terms: ['privée ', ''],
    index,
  })}à ${cityName}`;
}

function getMetaDescription({
  index,
  cityName,
}: {
  index: number;
  cityName: string;
}) {
  switch ((index % 7) + 1) {
    case 1:
      return `Recevez en 2 minutes vos devis gratuits pour des agents de sécurité à ${cityName} (agents de sécurité évènementiel, agents cynophiles, agents incendie, etc).`;
    case 2:
      return `Demandez gratuitement un devis pour des agents de sécurité à ${cityName} en deux minutes. Sécurisez vos évènements ou vos locaux à ${cityName} !`;
    case 3:
      return `Vous souhaitez faire appel à des agents de sécurité à ${cityName} et recevoir un devis gratuit ? Effectuez votre demande en 2 minutes sur Lead Advisor Security.`;
    case 4:
      return `Recevez gratuitement en deux minutes vos devis pour des prestations de sécurité privée à ${cityName} sur Lead Advisor Security.`;
    case 5:
      return `Lead Advisor Security : ${getRandomizedTerm({
        terms: ['entreprise', 'société', 'agence'],
        index,
      })} de sécurité ${getRandomizedTerm({
        terms: ['privée', ''],
        index,
      })} à ${cityName} : devis gratuit en 2 minutes.`;
    case 6:
      return `${getRandomizedTerm({
        terms: ['Entreprise', 'Société', 'Agence'],
        index,
      })} de sécurité ${getRandomizedTerm({
        terms: ['privée', ''],
        index,
      })} à ${cityName} : intervention sous 2 heures et devis gratuits en quelques minutes.`;
    case 7:
      return `${getRandomizedTerm({
        terms: ['Entreprise', 'Société', 'Agence'],
        index,
      })} de sécurité à ${cityName} : Demandez vos devis gratuits en deux minutes.`;
    default:
      break;
  }
}

function FirstParagraph({
  index,
  cityName,
  departmentNameWithPrefix,
  regionName,
  slug,
}: {
  index: number;
  cityName: string;
  departmentNameWithPrefix: string;
  regionName: string;
  slug: string;
}) {
  switch ((index % 3) + 1) {
    case 1:
      return (
        <>
          <h2>
            Prestations de surveillance : recevez vos devis gratuits pour des
            agents de sécurité à {cityName}
          </h2>
          <p>
            Demandez vos devis gratuits en 2 minutes pour des agents de sécurité
            à {cityName} sur Lead Advisor Security. Nous intervenons dans tout
            {` ${
              index % 2 === 0
                ? `le département ${departmentNameWithPrefix}.`
                : `toute la région ${regionName}.`
            }`}
          </p>
          <CTA href={`/devis-gardiennage?utm_campaign=${slug}`}>
            Recevez gratuitement vos devis pour des agents de sécurité en 2 min
          </CTA>
        </>
      );
    case 2:
      return (
        <>
          <h2>
            Prestations de gardiennage : faites appel à des agents de sécurité à{' '}
            {cityName}
          </h2>
          <p>
            Vous recherchez des agents de sécurité à {cityName} ? Déposez votre
            demande de devis en 2 minutes sur Lead Advisor Security. Vous
            recevrez vos chiffrages dans les plus brefs délais.
          </p>
          <CTA href={`/devis-gardiennage?utm_campaign=${slug}`}>
            Demandez vos devis gratuits pour des agents de sécurité en 2 min
          </CTA>
          <Box my={4} />
          <p>
            Nous pouvons faire intervenir à {cityName} tout type d’agent de
            sécurité :<br />
            <br />
            <ul>
              <li>
                {getRandomizedTerm({
                  terms: [
                    'Agent de Sécurité (ADS)',
                    'Agent de Prévention et de Sécurité (APS)',
                  ],
                  index,
                })}
              </li>
              <li>
                {getRandomizedTerm({
                  terms: ['Agent cynophile', 'Maître-chien'],
                  index,
                })}
              </li>
              <li>
                {getRandomizedTerm({
                  terms: [
                    'Agent de Prévention et de Sécurité Evènementiel (APSE)',
                    'Agent Evènementiel',
                  ],
                  index,
                })}
              </li>
              <li>Rondier intervenant</li>
              <li>
                {getRandomizedTerm({
                  terms: [
                    'Agent de sécurité incendie (SSIAP)',
                    'Agent incendie',
                  ],
                  index,
                })}
              </li>
              <li>
                {getRandomizedTerm({
                  terms: ['Agent de sécurité magasin', 'Inspecteur de magasin'],
                  index,
                })}
              </li>
              <li>
                {getRandomizedTerm({
                  terms: ['Agent d’accueil', 'Agent de contrôle d’accès'],
                  index,
                })}
              </li>
            </ul>
          </p>
          <p>
            Pour consulter une description détaillée de chaque type d’agent de
            sécurité, nous vous recommandons la lecture de notre{' '}
            <a
              href="/articles/agent-de-securite-la-fiche-metier"
              target="_blank"
              rel="noopener noreferrer"
            >
              fiche métier sur les agents de sécurité.
            </a>
          </p>
        </>
      );
    case 3:
      return (
        <>
          <h2>Besoin d’agents de sécurité à {cityName} ?</h2>
          <p>
            Vous avez besoin de faire appel à des agents de sécurité pour un
            évènement ou bien des locaux à {cityName} ? Effectuez votre demande
            de devis en deux minutes !
          </p>
          <Box my={4} />
          <CTA href={`/devis-gardiennage?utm_campaign=${slug}`}>
            Déposez votre demande pour des agents de sécurité en 2 min
          </CTA>
          <Box my={4} />
          <p>
            Nous mettons à votre dispositions tous les types d’agents de
            sécurité suivants :
            <ul>
              <li>
                <a
                  href="/articles/agent-de-prevention-et-de-securite-aps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Agent de Prévention et de Sécurité (APS)
                </a>
              </li>
              <li>
                <a
                  href="/articles/agent-de-securite-la-fiche-metier#rondier-intervenant"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rondier intervenant
                </a>
              </li>
              <li>
                <a
                  href="/articles/maitre-chien"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getRandomizedTerm({
                    terms: ['Agent cynophile', 'Maître-chien'],
                    index,
                  })}
                </a>
              </li>
              <li>
                <a
                  href="/articles/agent-de-securite-evenementiel"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getRandomizedTerm({
                    terms: [
                      'Agent de Prévention et de Sécurité Evènementiel (APSE)',
                      'Agent de sécurité évènementiel',
                    ],
                    index,
                  })}
                </a>
              </li>
              <li>
                <a
                  href="/articles/agent-de-securite-incendie-ssiap"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getRandomizedTerm({
                    terms: [
                      'Agent de sécurité incendie (SSIAP)',
                      'Agent incendie',
                    ],
                    index,
                  })}
                </a>
              </li>
              <li>
                <a
                  href="/articles/agent-de-securite-magasin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getRandomizedTerm({
                    terms: [
                      'Agent de sécurité magasin',
                      'Inspecteur de magasin',
                    ],
                    index,
                  })}
                </a>
              </li>
              <li>
                <a
                  href="/articles/agent-de-securite-la-fiche-metier#agent-daccueil-et-de-controle-dacces"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getRandomizedTerm({
                    terms: ['Agent d’accueil', 'Agent de contrôle d’accès'],
                    index,
                  })}
                </a>
              </li>
            </ul>
          </p>
        </>
      );
    default:
      console.log('Index wrong value');
      break;
  }
}

function SecondParagraph({
  index,
  cityName,
  slug,
}: {
  index: number;
  cityName: string;
  slug: string;
}) {
  switch ((index % 3) + 1) {
    case 1:
      return (
        <>
          <h2>Sécurisez vos locaux à {cityName}</h2>
          <p>
            {`${getRandomizedTerm({
              terms: ['Étant donné', 'Avec'],
              index,
            })} `}
            <a
              href="/articles/l-explosion-de-l-insecurite-en-france-mythe-ou-realite"
              target="_blank"
              rel="noopener noreferrer"
            >
              la montée de l’insécurité en France
            </a>
            {`, il est ${getRandomizedTerm({
              terms: ['recommandé', 'conseillé'],
              index,
            })} de ${getRandomizedTerm({
              terms: ['sécuriser', 'protéger'],
              index,
            })} vos locaux d’entreprise, d’association, ou votre commerce à ${cityName} en mettant en place l’une ou plusieurs des solutions suivantes :`}
          </p>
          <ul>
            <li>Un système de contrôle d’accès</li>
            <li>Un système de vidéosurveillance</li>
            <li>Un système de télésurveillance</li>
            <li>Un système d’alarme intrusion</li>
            <li>Un système d’alarme incendie</li>
            <li>Un portique de sécurité et des antivols</li>
            <li>Un dispositif composé d’agents de sécurité</li>
          </ul>
          <CTA href={`/devis-securite?utm_campaign=${slug}`}>
            Demandez vos devis pour sécuriser vos locaux en 2 min
          </CTA>
          <Box my={4} />
          <p>
            Pour en savoir plus sur les différents dispositifs de sécurité
            possible (alarme, vidéosurveillance, surveillance humaine), vous
            pouvez consulter{' '}
            <a
              href="/articles/securite-entreprise-le-guide-complet"
              target="_blank"
              rel="noopener noreferrer"
            >
              notre guide complet sur la sécurité en entreprise
            </a>
            .
          </p>
        </>
      );
    case 2:
      return (
        <>
          <h2>Protégez votre entreprise à {cityName}</h2>
          <p>
            Pour éviter tout risque de sinistre et pour vous concentrer
            sereinement sur votre coeur de métier, protégez votre entreprise des
            cambrioleurs, des dégradations, et des incendies.
          </p>
          <p>
            Il faut savoir qu’après un sinistre grave tel qu’un incendie, une
            explosion, ou une inondation, 75% des entreprises ferment dans les 3
            ans, les conséquences étant nombreuses et très coûteuses :
            destruction de stocks et de matériels, perte de clientèle, perte de
            journées d’exploitation, travaux de remises en état... Il est donc
            très important d’être bien assuré, mais aussi et surtout de protéger
            et de surveiller votre entreprise avec des systèmes adaptés afin de
            tout mettre en oeuvre pour éviter un sinistre :
          </p>
          <p>
            <br />
            <br />
            <ul>
              <li>
                <a
                  href="https://www.leadadvisor.fr/articles/securite-entreprise-le-guide-complet#32-controle-dacces-en-entreprise"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contrôle d’accès
                </a>
              </li>
              <li>
                <a
                  href="/articles/securite-entreprise-le-guide-complet#34-videosurveillancetelesurveillance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vidéosurveillance
                </a>
              </li>
              <li>
                <a
                  href="/articles/securite-entreprise-le-guide-complet#34-videosurveillancetelesurveillance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Télésurveillance
                </a>
              </li>
              <li>
                <a
                  href="/articles/securite-entreprise-le-guide-complet#33-systeme-dalarme"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Alarme intrusion
                </a>
              </li>
              <li>
                <a
                  href="/articles/securite-entreprise-le-guide-complet#2-la-securite-incendie"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Alarme incendie
                </a>
              </li>
              <li>
                <a
                  href="/articles/securite-entreprise-le-guide-complet#4-prevention-des-inondations"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Alarme inondation
                </a>
              </li>
              <li>
                <a
                  href="/articles/securite-entreprise-le-guide-complet#31-protections-physiques"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Portique de sécurité antivols
                </a>
              </li>
              <li>
                <a
                  href="/articles/agent-de-securite-la-fiche-metier"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Agents de sécurité
                </a>
              </li>
            </ul>
          </p>
          <CTA href={`/devis-securite?utm_campaign=${slug}`}>
            Demandez vos devis pour sécuriser votre entreprise en 2 min
          </CTA>
        </>
      );
    case 3:
      return (
        <>
          <h2>Sécurisez votre entreprise ou votre résidence à {cityName}</h2>
          <p>
            La mise en place d’un dispositif de sécurité (agents de sécurité,
            caméras, alarme…) a pour premier bénéfice un effet fortement
            dissuasif. Une boutique, une entreprise, ou une habitation bien
            surveillée et protégée par exemple par un maître-chien dissuadera
            les malfaiteurs de passer à l’acte.
          </p>
          <p>
            Le second bénéfice réside bien sûr dans la surveillance effective du
            lieu :
            <ul>
              <li>
                Les agents de sécurité observeront le local et ses alentours
                avec vigilance, et relèveront toute anomalie en le notant dans
                le registre de main courante.
              </li>
              <li>
                Le système de vidéosurveillance permettra de surveiller le local
                en temps réel et d’enregistrer toutes les images.
              </li>
              <li>
                Le système de télésurveillance alertera la société de sécurité
                prestataire en cas d’intrusion. Des opérateurs de l’entreprise
                de sécurité procéderont alors à des contrôles, puis des agents
                de sécurité se rendront sur place pour effectuer la levée de
                doute.
              </li>
            </ul>
          </p>
          <p>
            Enfin, le dispositif de sécurité permet d’intervenir et d’agir en
            cas d’intrusion ou d’autres problèmes (incendie, inondation, etc.).
            Les agents de sécurité pourront prévenir la police, et appréhender
            les malfaiteurs pour les livrer à un officier de police si la
            situation le permet.
          </p>
          <p>
            En résumé, un dispositif de sécurité adapté à votre situation
            permettra de dissuader les malfaiteurs, de surveiller le lieu en
            temps réel, et d’intervenir en cas d’alerte.
          </p>
          <CTA href={`/devis-securite?utm_campaign=${slug}`}>
            Demandez vos devis de sécurité en 2 min
          </CTA>
        </>
      );
    default:
      console.log('Index wrong value');
      break;
  }
}

function ThirdParagraph({
  index,
  cityName,
  crimePer1000,
  theftPer1000,
  departmentName,
  departmentCrimePer1000,
  departmentTheftPer1000,
  cityCode,
  wikiImage,
}: {
  index: number;
  cityName: string;
  crimePer1000: number;
  theftPer1000: number;
  departmentName: string;
  departmentCrimePer1000: number;
  departmentTheftPer1000: number;
  cityCode: string;
  wikiImage: any;
}) {
  const crimePer1000Number = parseFloat(crimePer1000);

  return crimePer1000Number >= 18 ? (
    <div>
      <h2>
        {`Le haut niveau d’insécurité à ${cityName} et la nécessité de faire
            appel à une ${getRandomizedTerm({
              terms: ['agence', 'entreprise', 'société de sécurité'],
              index,
            })}`}
      </h2>
      <p>
        {cityName} est une ville au niveau d’insécurité élevé. En effet, le taux
        de cas de violence pour 10 000 habitants, égal à {crimePer1000} y est
        nettement plus élevé que le taux moyen national, qui se monte à 8.65. Il
        est donc recommandé de sécuriser ses locaux et ses événements à{' '}
        {cityName} afin d’éviter tout problème de débordements, d’agressions, ou
        de vols.
      </p>
      <h3>
        Violences aux personnes recensées en 2014 en nombre de cas pour 1000
        habitants
      </h3>
      <table>
        <tr>
          <DataCell bold>{cityName}</DataCell>
          <DataCell bold>{crimePer1000}</DataCell>
        </tr>
        <tr>
          <DataCell>{departmentName}</DataCell>
          <DataCell>{departmentCrimePer1000}</DataCell>
        </tr>
        <tr>
          <DataCell>France</DataCell>
          <DataCell>8,65</DataCell>
        </tr>
      </table>
      <h3>
        Vols et dégradations recensés en 2014 en nombre de cas pour 1000
        habitants
      </h3>
      <table>
        <tr>
          <DataCell bold>{cityName}</DataCell>
          <DataCell bold>{theftPer1000}</DataCell>
        </tr>
        <tr>
          <DataCell>{departmentName}</DataCell>
          <DataCell>{departmentTheftPer1000}</DataCell>
        </tr>
        <tr>
          <DataCell>France</DataCell>
          <DataCell>30,56</DataCell>
        </tr>
      </table>
      <i>
        {`Source : `}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`http://www.linternaute.com/actualite/delinquance/${slugify(
            cityName
          )}/ville-${cityCode}`}
        >
          {`http://www.linternaute.com/actualite/delinquance/${slugify(
            cityName
          )}/ville-${cityCode}`}
        </a>
      </i>
      <ImageWrapper>
        <Img {...wikiImage.childImageSharp} alt={cityName} />
      </ImageWrapper>
    </div>
  ) : crimePer1000Number >= 10 ? (
    <div>
      <h2>L’insécurité à {cityName}</h2>
      <p>
        L’insécurité est relativement présente à {cityName} et doit inciter à la
        vigilance. Le taux de violence enregistré dans cette grande ville du
        département {departmentName} ({crimePer1000}) est supérieur à celui de
        la moyenne nationale (8.65).
      </p>
      <ImageWrapper>
        <Img {...wikiImage.childImageSharp} alt={cityName} />
      </ImageWrapper>
      <Box my={4} />
      <h3>
        Violences aux personnes recensées en 2014 en nombre de cas pour 1000
        habitants
      </h3>
      <table>
        <tr>
          <DataCell bold>{cityName}</DataCell>
          <DataCell bold>{crimePer1000}</DataCell>
        </tr>
        <tr>
          <DataCell>{departmentName}</DataCell>
          <DataCell>{departmentCrimePer1000}</DataCell>
        </tr>
        <tr>
          <DataCell>France</DataCell>
          <DataCell>8,65</DataCell>
        </tr>
      </table>
      <h3>
        Vols et dégradations recensés en 2014 en nombre de cas pour 1000
        habitants
      </h3>
      <table>
        <tr>
          <DataCell bold>{cityName}</DataCell>
          <DataCell bold>{theftPer1000}</DataCell>
        </tr>
        <tr>
          <DataCell>{departmentName}</DataCell>
          <DataCell>{departmentTheftPer1000}</DataCell>
        </tr>
        <tr>
          <DataCell>France</DataCell>
          <DataCell>30,56</DataCell>
        </tr>
      </table>
      <i>
        {`Source : `}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`http://www.linternaute.com/actualite/delinquance/${slugify(
            cityName
          )}/ville-${cityCode}`}
        >
          {`http://www.linternaute.com/actualite/delinquance/${slugify(
            cityName
          )}/ville-${cityCode}`}
        </a>
      </i>
    </div>
  ) : null;
}

function FourthParagraph({
  index,
  cityName,
  slug,
}: {
  index: number;
  cityName: string;
  slug: string;
}) {
  switch ((index % 3) + 1) {
    case 1:
      return (
        <>
          <h2>
            Comment choisir{' '}
            {getRandomizedTerm({
              terms: ['son entreprise', 'sa société'],
              index,
            })}{' '}
            de sécurité privée à {cityName}
          </h2>
          <p>
            Le choix de{' '}
            {getRandomizedTerm({
              terms: ['son entreprise', 'sa société'],
              index,
            })}{' '}
            de sécurité privée doit se faire avec attention. En effet, le
            secteur de la sécurité privée (gardiennage, surveillance) se révèle
            très inégal au niveau de la qualité de prestation et de la
            fiabilité. Que ce soit pour une soirée ou pour un contrat longue
            durée, un agent de sécurité compétent, sachant faire face aux
            situations de crise et communiquer de façon claire, contribuera à
            éviter des drames potentiels.
          </p>
          <p>
            Lead Advisor Security vous permet de trouver votre agence de
            sécurité à {cityName} en déposant votre demande de devis en deux
            minutes, par le biais d’un très rapide QCM adaptif. Vous recevrez en
            retour jusqu’à 6 propositions de la part d’entreprises de sécurité
            privée en mesure d’intervenir à {cityName} et motivée pour répondre
            à votre demande, ce qui vous permettra d’établir une comparaison des
            prestations et d’effectuer le bon choix.
          </p>
          <CTA href={`/devis-securite?utm_campaign=${slug}`}>
            Recevez vos devis gratuits en 2 min
          </CTA>
          <Box my={4} />
          <p>
            Voici quelques critères à prendre en compte pour sélectionner votre
            société de sécurité :
            <br />
            <br />
            <ul>
              <li>
                La structure doit être immatriculée au registre du commerce et
                des sociétés. Demandez l’extrait Kbis de l’entreprise et
                vérifiez son immatriculation sur{' '}
                <a
                  href="https://www.infogreffe.fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Infogreffe
                </a>
                .
              </li>
              <li>
                L’entreprise doit détenir une autorisation d’exercer délivrée
                par le{' '}
                <a
                  href="http://www.cnaps.interieur.gouv.fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Conseil National des Activités Privées de Sécurité
                </a>
                .
              </li>
              <li>
                Les agents de sécurité mis à votre disposition doivent posséder
                une carte professionnelle en cours de validité.
              </li>
              <li>
                Les tarifs doivent être compétitifs, sans être trop bas. Un prix
                trop bas (inférieur à 17€ HT de l’heure) doit vous inciter à la
                méfiance quant à la fiabilité de l’entreprise et de ses agents.
              </li>
            </ul>
            Nous vous invitons à consulter nos{' '}
            <a
              href="/articles/bien-choisir-sa-societe-de-gardiennage"
              target="_blank"
              rel="noopener noreferrer"
            >
              conseils sur le choix de votre société de gardiennage
            </a>{' '}
            pour plus d’informations à ce sujet.
          </p>
        </>
      );
    case 2:
      return (
        <>
          <h2>Votre entreprise de sécurité à {cityName}</h2>
          <p>
            Lead Advisor Security vous permet de trouver très simplement et
            rapidement votre agence de sécurité à {cityName} en déposant votre
            demande de devis par le biais d’un bref QCM. Les sociétés de
            sécurité intéressées par votre demande vous contacteront ensuite et
            vous enverront leur proposition. Les offres que vous recevrez vous
            permettront d’établir une comparaison et de choisir la société vous
            paraissant la plus fiable et proposant le meilleur rapport
            qualité/prix.
          </p>
          <CTA href={`/devis-securite?utm_campaign=${slug}`}>
            Devis gratuits en 2 min pour des prestations de gardiennage et de
            surveillance
          </CTA>
          <Box my={4} />
          <p>
            Pour des prestations de gardiennage, le prix ne doit surtout pas
            constituer votre unique critère. Le secteur de la surveillance
            humaine doit en effet encore se professionnaliser pour gagner en
            fiabilité. Votre priorité doit donc se porter sur le
            professionnalisme et la qualité des prestations afin de gagner en
            sérénité en ce qui concerne la sécurisation de votre évènement ou de
            vos locaux.
          </p>
          <p>
            Pour plus d’informations, nous vous recommandons la lecture de notre
            article sur{' '}
            <a
              href="/articles/demander-un-devis-pour-des-agents-de-securite"
              target="_blank"
              rel="noopener noreferrer"
            >
              les prix et les devis pour des agents de sécurité
            </a>
            .
          </p>
        </>
      );
    case 3:
      return (
        <>
          <h2>Agence de sécurité à {cityName}</h2>
          <p>
            <a
              href="https://www.ouest-france.fr/societe/en-france-la-securite-privee-est-un-secteur-en-plein-boom-6219437"
              target="_blank"
              rel="noopener noreferrer"
            >
              Le marché de la sécurité privée (gardiennage et surveillance)
              connaît un succès qui ne s’estompe pas
            </a>
            . Au contraire, il va poursuivre sa croissance, notamment avec les
            Jeux Olympiques de 2024. Néanmoins, ce secteur a encore besoin de se
            professionnaliser et de se solidifier, afin de proposer un meilleur
            niveau général de fiabilité. Trop de décideurs se tournent encore
            vers le moins-disant, ce qui a pour conséquence un nivellement des
            prix vers le bas, préjudiciable à la solidité des petites
            entreprises de sécurité et à la qualité des prestations.
          </p>
          <p>
            Dans ces conditions, comment trouver une entreprise de sécurité
            fiable et sérieuse à {cityName} ? Lead Advisor Security vous propose
            de déposer de façon très simple et rapide votre demande de devis par
            le biais d’un QCM concis. Vous serez alors contacté très rapidement
            par les agences de sécurité capables d’intervenir à {cityName} sous
            les meilleurs délais et motivées pour fournir la prestation de
            sécurité attendue.
          </p>
          <CTA href={`/devis-securite?utm_campaign=${slug}`}>
            Demandez vos devis gratuits
          </CTA>
          <p>
            Vous pourrez alors choisir sereinement la société de sécurité
            présentant les meilleures garanties et la plus grande fiabilité pour
            sécuriser votre événement (cocktail d’entreprise, conférence, salon,
            mariage, anniversaire, soirée privée...) ou bien votre entreprise
            (immeuble, site industriel, boutique, bureaux...).
          </p>
        </>
      );
    default:
      console.log('Index wrong value');
      break;
  }
}

function FifthParagraph({
  cityName,
  departmentCode,
  index,
  companyCount,
  companies,
  slug,
}: {
  cityName: string;
  departmentCode: string;
  index: number;
  companyCount: number;
  companies: {
    name: string;
    address: string;
    codeAndCity: string;
  }[];
  slug: string;
}) {
  const partners = ALL_PARTNERS.filter(
    (partner) => partner.codeDepartement === departmentCode
  );

  return (
    <>
      <h2>
        Les{' '}
        {getRandomizedTerm({
          terms: ['sociétés', 'entreprises'],
          index,
        })}{' '}
        de sécurité à {cityName}
      </h2>
      <Box my={4} />
      <CTA href={`/devis-gardiennage?utm_campaign=${slug}`}>
        Demandez votre devis pour des agents de sécurité à {cityName} en 2 min
      </CTA>
      <Box my={4} />
      <Box>
        {partners.length > 0 ? (
          <Box>
            <p>Voici nos partenaires intervenants à {cityName} :</p>
            {partners.map((partner) => (
              <Box my={3}>
                <a
                  href={partner.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {partner.name}
                </a>
                <Box>{partner.address}</Box>
                <Box>{partner.codeAndCity}</Box>
                <Box>
                  <img src={partner.imageUrl} alt={partner.name} />
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <>
            <p>
              Au 20 février 2020, on dénombre {companyCount} entreprises de
              sécurité à {cityName}. Voici quelques-unes de ces sociétés :
            </p>
            <Box>
              {companies.slice(0, 10).map((company) => (
                <Box my={3}>
                  <Box>{company.name}</Box>
                  <Box>{company.address}</Box>
                  <Box>{company.codeAndCity}</Box>
                </Box>
              ))}
            </Box>
            <p>
              <a
                href={`https://www.societe.com/cgi-bin/liste?ens=on&nom=&ape=8010Z&adr=&num=&ville=${slugify(
                  cityName
                )}&dep=`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Liste complète des entreprises de sécurité à {cityName}
              </a>
            </p>
          </>
        )}
      </Box>
    </>
  );
}

function RandomizedImage({ index, images, alts }) {
  return (
    <ImageWrapper>
      <Img
        {...images[index % images.length].childImageSharp}
        alt={alts[index % alts.length]}
      />
    </ImageWrapper>
  );
}

function TemplateContent({ helmet, pageContext, data, title, slug }) {
  const {
    paragraph1Image1,
    paragraph1Image2,
    paragraph1Image3,
    paragraph1Image4,
    paragraph2Image1,
    paragraph2Image2,
    paragraph2Image3,
    wikiImage,
    imageWithCityName1,
    imageWithCityName2,
    imageWithCityName3,
  } = data;

  const index = pageContext.cityRandomIndex;
  const cityName = pageContext.city.nom;

  const permutations = cartesian([0, 1, 2], [0, 1, 2], [0, 1, 2]);
  // console.log({
  //   cityName,
  //   combinaison:
  //     permutations[pageContext.cityRandomIndex % permutations.length],
  //   cityRandomIndex: pageContext.cityRandomIndex,
  // });

  const [
    firstParagraphIndex,
    secondParagraphIndex,
    fourthParagraphIndex,
  ] = permutations[pageContext.cityRandomIndex % permutations.length];

  return (
    <ArticleContainer>
      <article className="mx-auto prose prose-lg text-gray-600 prose-indigo">
        {helmet || ''}
        <h1>
          <span className="block mb-4 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
            {title}
          </span>
        </h1>
        <Box my={50}>
          <FirstParagraph
            index={firstParagraphIndex}
            cityName={cityName}
            departmentNameWithPrefix={pageContext.department.nom}
            regionName={pageContext.department.region.nom}
            slug={slug}
          />
        </Box>
        <ImageWrapper>
          <RandomizedImage
            index={index}
            images={[
              paragraph1Image1,
              paragraph1Image2,
              paragraph1Image3,
              paragraph1Image4,
            ]}
            alts={[
              `agent de securite ${cityName}`,
              `societe agent de securite ${cityName}`,
              `entreprise agent de securite ${cityName}`,
              `agence agent de securite ${cityName}`,
            ]}
          />
        </ImageWrapper>
        <Box my={4} />
        <SecondParagraph
          index={secondParagraphIndex}
          cityName={cityName}
          departmentNameWithPrefix={pageContext.department.nom}
          regionName={pageContext.department.region.nom}
          slug={slug}
        />
        <Box my={4} />
        <RandomizedImage
          index={index}
          images={[paragraph2Image1, paragraph2Image2, paragraph2Image3]}
          alts={[
            `societe de securite privee`,
            `agence de securite privee`,
            `entreprise de securite privee`,
          ]}
        />
        <Box my={4} />
        <ThirdParagraph
          index={index}
          cityName={cityName}
          crimePer1000={pageContext.city.crime.violence}
          theftPer1000={pageContext.city.crime.theft}
          departmentCrimePer1000={pageContext.city.region.violence}
          departmentTheftPer1000={pageContext.city.region.theft}
          departmentName={pageContext.department.nom}
          cityCode={pageContext.city.code}
          wikiImage={wikiImage}
        />
        <Box my={4} />
        <FourthParagraph
          cityName={cityName}
          index={fourthParagraphIndex}
          slug={slug}
        />
        <Box my={4} />
        <RandomizedImage
          index={index}
          images={[imageWithCityName1, imageWithCityName2, imageWithCityName3]}
          alts={[
            `agence de securite ${cityName}`,
            `societe de securite ${cityName}`,
            `entreprise de securite ${cityName}`,
          ]}
        />
        <Box my={4} />
        <FifthParagraph
          cityName={cityName}
          departmentCode={pageContext.city.codeDepartement}
          index={index}
          companyCount={pageContext.city.companyCount}
          companies={pageContext.companies}
          slug={slug}
        />
      </article>
    </ArticleContainer>
  );
}

const RandomizedCityTemplate = ({ pageContext, data, ...props }) => {
  const { site } = data;

  if (!pageContext.city) {
    console.log(pageContext);
  }
  const title = getRandomizedTitle({
    index: pageContext.cityRandomIndex,
    cityName: pageContext.city.nom,
  });
  const slug = `${slugify(pageContext.department.region.nom)}/${slugify(
    pageContext.department.nom
  )}/${slugify(pageContext.city.nom)}`;

  return (
    <Layout
      sidebarContent={null}
      rightsidebarContent={
        <div className="flex justify-center py-2 mt-12 shadow-md xl:shadow-none bg-indigo-50 xl:bg-transparent xl:mr-8 xl:p-0">
          <a
            href={`https://leadadvisor.fr/devis-gardiennage?utm_campaign=${slug}`}
          >
            <Button variant="secondary" className="xl:animate-pump">
              Demander un devis gratuit
            </Button>
          </a>
        </div>
      }
    >
      <TemplateContent
        title={title}
        slug={slug}
        helmet={
          <Helmet titleTemplate="%s">
            {pageContext.city.nom === 'Lille' ? (
              <title>{`${title} - Devis gratuits - Lead Advisor Security`}</title>
            ) : (
              <title>{`${title} - Lead Advisor`}</title>
            )}
            <link
              rel="canonical"
              href={`${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slug}`}
            />
            {/* {pageContext.cityRandomIndex % 2 === 0 ? (
              <meta charSet="utf-8" />
            ) : (
              <meta charSet="iso-8859-1" />
            )} */}
            <meta
              name="description"
              content={getMetaDescription({
                index: pageContext.cityRandomIndex,
                cityName: pageContext.city.nom,
              })}
            />
            <meta property="og:title" content={title} />
            <meta
              property="og:url"
              content={`${site.siteMetadata.siteUrl}/${slug}`}
            />
            <meta name="author" content="David Khoy de Lead Advisor" />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@lead_advisor" />
            <meta name="twitter:title" content={title} />
            <meta
              name="twitter:url"
              content={`${site.siteMetadata.siteUrl}/${slug}`}
            />
            <meta
              name="twitter:description"
              content={getMetaDescription({
                index: pageContext.cityRandomIndex,
                cityName: pageContext.city.nom,
              })}
            />
            <meta name="twitter:image" content="/img/og-image.jpg" />

            <script type="application/ld+json">
              {`
{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite",
    "name": "Agent de sécurité"
  },{
    "@type": "ListItem",
    "position": 2,
    "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slugify(
                pageContext.department.region.nom
              )}",
    "name": "${pageContext.department.region.nom}"
  },{
    "@type": "ListItem",
    "position": 3,
     "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slugify(
                pageContext.department.region.nom
              )}/${slugify(pageContext.department.nom)}",
    "name": "${pageContext.department.nom}"
  },{
    "@type": "ListItem",
    "position": 4,
     "item": "${site.siteMetadata.siteUrl}/articles/agent-de-securite/${slugify(
                pageContext.city.region.nom
              )}/${slugify(pageContext.department.nom)}/${slugify(
                pageContext.city.nom
              )}",
    "name": "${pageContext.city.nom}"
  }]
}
`}
            </script>
          </Helmet>
        }
        pageContext={pageContext}
        data={data}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query randomizedCitySiteMetadata(
    $cityImagePath: String
    $imageWithCityName1: String
    $imageWithCityName2: String
    $imageWithCityName3: String
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    paragraph1Image1: file(
      relativePath: { eq: "randomized/agent-de-securite-APS.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paragraph1Image2: file(
      relativePath: { eq: "randomized/societe-agent-de-securite.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paragraph1Image3: file(
      relativePath: { eq: "randomized/entreprise-agent-de-securite.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paragraph1Image4: file(
      relativePath: { eq: "randomized/agence-agent-de-securite.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paragraph2Image1: file(
      relativePath: { eq: "randomized/societe-de-securite-privee.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paragraph2Image2: file(
      relativePath: { eq: "randomized/agence-de-securite-privee.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paragraph2Image3: file(
      relativePath: { eq: "randomized/entreprise-de-securite-privee.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wikiImage: file(relativePath: { eq: $cityImagePath }) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageWithCityName1: file(relativePath: { eq: $imageWithCityName1 }) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageWithCityName2: file(relativePath: { eq: $imageWithCityName2 }) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageWithCityName3: file(relativePath: { eq: $imageWithCityName3 }) {
      childImageSharp {
        fluid(maxWidth: 650, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default RandomizedCityTemplate;
